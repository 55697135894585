.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
	background-color: $primary;
	text-shadow: unset;
}

.bootstrap-datetimepicker-widget table {
	th {
		font-size: $font-size-12;
		font-weight: $font-weight-semibold;
		color: $gray-600;
		width: 26px;
		height: 24px;
		line-height: 24px;

		&.prev,
		&.next {
			padding-bottom: 8px;

			span {
				width: 20px;
				height: 20px;
				font-size: $font-size-12;
				border: 1px solid $light-900;
				@include rounded(50%);
				display: -webkit-inline-box;
				display: -ms-inline-flexbox;
				display: inline-flex;
				align-items: center;
				-webkit-box-align: center;
				-ms-flex-align: center;
				justify-content: center;
				-webkit-justify-content: center;
				-ms-flex-pack: center;

				&:hover {
					background: $dark;
					border-color: $dark;
					color: $white;
				}
			}
		}
	}

	td {
		font-size: $font-size-12;
		color: $gray-900;

		&.day {
			width: 26px;
			height: 24px;
			line-height: 24px;
		}
	}
}

.bootstrap-datetimepicker-widget {
	z-index: 999 !important;

	.picker-switch {
		padding-bottom: 8px;
	}
}

.bootstrap-datetimepicker-widget table td.today:before {
	border-bottom-color: $primary;
}

.bootstrap-datetimepicker-widget table thead tr:first-child th:hover {
	background: transparent;
}

.border-success {
	border-color: $success !important;
}

.shadow-sm {
	box-shadow: $box-shadow-sm !important;
}

.shadow-md {
	box-shadow: $box-shadow-md !important;
}

.list-group-item {
	border-color: $light-900 !important;
	color: inherit;
}

.list-group {
	a {
		&.active {
			background: $primary-100;
			color: $primary;
		}

		&:hover {
			background: $primary-100;
			color: $primary;
		}
	}
}

.list-styled-dotted {
	li {
		list-style-type: disc;
	}
}

.custom-offcanvas {
	&.offcanvas.offcanvas-end {
		width: 600px !important;

		.offcanvas-header {
			.close-btn {
				width: 20px;
				height: 20px;
				color: $white;
				@include rounded(50%);
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				align-items: center;
				-webkit-box-align: center;
				-ms-flex-align: center;
				justify-content: center;
				-webkit-justify-content: center;
				-ms-flex-pack: center;
				background-color: $gray-500;
				border: 1px solid $gray-500;

				&:hover {
					background: $danger;
					border-color: $danger;
				}
			}
		}
	}
}

.w-40 {
	width: 40%;

	@include respond-below(md) {
		width: 100%;
	}
}

hr {
	border-color: $light-900;
}

.link-primary {
	&.text-decoration-underline {
		&:hover {
			text-decoration-color: $primary !important;
		}
	}
}

.sidbarnews {
	width: 100%;
	height: 100%;
	overflow: auto;
}

#overlayer {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	z-index: 9999;
	background: #fff;
}

.loader {
	display: inline-block;
	width: 40px;
	height: 40px;
	position: fixed;
	z-index: 10000;
	border: 4px solid $secondary;
	top: 50%;
	animation: loader 2s infinite ease;
	left: 50%;
	border-radius: 5px;
}

.loader-inner {
	vertical-align: top;
	display: inline-block;
	width: 100%;
	background-color: $secondary;
	animation: loader-inner 2s infinite ease-in;
}

@keyframes loader {
	0% {
		transform: rotate(0deg);
	}

	25% {
		transform: rotate(180deg);
	}

	50% {
		transform: rotate(180deg);
	}

	75% {
		transform: rotate(360deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes loader-inner {
	0% {
		height: 0%;
	}

	25% {
		height: 0%;
	}

	50% {
		height: 100%;
	}

	75% {
		height: 100%;
	}

	100% {
		height: 0%;
	}
}