.header-two {
	.bar-icon {
		span {
			background-color: #ff008a;
		}
	}
	.menu-close {
		color: #ff008a;
	}
	.header-navbar-rht {
		li {
			>a.header-login {
				background: #ff008a;
				border: 1px solid #ff008a;
				box-shadow: inset 0 70px 0 0 #ff008a;
				border-radius: 40px;
				font-size: 16px;
				font-weight: 500;
                color: $white;
				&:hover {
					background: #e2017b;
					border: 1px solid #e2017b;
					box-shadow: inset 0 70px 0 0 #e2017b;
				}
			}
		}
	}
	.main-nav {
		li {
			a {
				&:hover {
					color: #ff008a !important;
				}
			}
		}
	}
}
.header-two.fixed {
	top: 0;
}
.header-five {
	.menu-close {
		color: #ff008a;
	}
}
.header-six {
	.menu-close {
		color: #ff008a;
	}
}
.header-seven {
	.menu-close {
		color: #ff008a;
	}
}
.header-eight {
	.menu-close {
		color: #ff008a;
	}
}
.header-nine {
	.menu-close {
		color: #ff008a;
	}
}

.top-bar {
	background: #3E4265;
	padding: 10px 0;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	flex-wrap: wrap;
	width: 100%;
	@include respond-below(lg) {
		display: none;
	}
	h6 {
		font-weight: 600;
		font-size: 14px;
		color: #fff;
		margin: 0 24px 0 0;
	}
	ul {
		li {
			width: 30px;
			height: 30px;
			background: #fff;
			border-radius: 6px;
			font-weight: 600;
			font-size: 12px;
			color: #ff008a;
			display: -ms-inline-flexbox;
			display: inline-flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			justify-content: center;
			-webkit-justify-content: center;
			-ms-flex-pack: center;
			margin-right: 12px;
			&:last-child {
				margin-right: 0;
			}
		}
	}
}
.top-close {
	color: #fff;
	position: absolute;
	right: 20px;
	&:hover {
		color: #ff008a;
	}
}
.header-two.fixed {
	top: 0;
}
