header {
	.index-2 {
		.main-menu-wrapper {

			.navbar-logo {
				a {
					img {
						height: auto;
						max-width: 100%;
						max-height: 35px;
					}
				}
			}

			nav {
				.main-nav {
					li {
						&:hover>a {
							background: transparent !important;
							// -webkit-background-clip: text;
							-webkit-text-fill-color: initial;
							color: $primary !important;

							&::before {
								background: none !important;
								// -webkit-background-clip: text;
								-webkit-text-fill-color: initial;
								color: $primary !important;
							}
						}

						.submenu {
							border-top: none !important;
						}
					}

					.header-btn {
						.header-reg {
							font-weight: 500;
							color: #28283C;
							text-decoration: none;
							padding: 0;
						}
					}
				}
			}
		}
	}
}