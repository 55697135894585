.collapse-enter {
  max-height: 0;
  overflow: hidden;
  transition: max-height 300ms ease-in-out;
}

.collapse-enter-active {
  max-height: 1000px;
}

.collapse-exit {
  max-height: 1000px;
  overflow: hidden;
  transition: max-height 300ms ease-in-out;
}

.collapse-exit-active {
  max-height: 0;
}

.notification-badge {
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 0.75rem;
  padding: 0.5em;
  border-radius: 50%;
}