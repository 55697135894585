// Base Font
$font-size-base: 14px;
$font-size-sm: $font-size-base * .875;
$font-size-lg: $font-size-base * 1.25;
$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

// Font Family
$font-family-primary: "Archivo", sans-serif;
$font-awesome: "Fontawesome";
	
// Theme Colors Variables
$primary: #e64783;
$primary-blue:#8093dc;
$primary-hover: darken($primary, 10%);
$secondary: #8093dc;
$secondary-pink:#e64783;
$secondary-hover: darken($secondary, 10%);
$success: #03C95A;
$success-hover: darken($success, 10%);
$info: #656B76;
$info-hover: darken($info, 10%);
$warning: #FFC107;
$warning-hover: darken($warning, 10%);
$danger: #E70D0D;
$danger-hover: darken($danger, 10%);
$dark: #111827;
$light: #E9EAEB;
$light-hover: darken($light, 10%);
$white: #FFF;
$black: #000;
$purple: #AB47BC;
$pink: #e64783;
$skyblue: #0DCAF0;

//Dark
$dark-primary:#0D0D0D;
$dark-secondary:#181717;

//Dark Mode Light
$dark-light-100: #0F0F0F;
$dark-light-200: #111111;
$dark-light-300: #131313;
$dark-light-400: #151515;
$dark-light-500: #171717;
$dark-light-600: #191919;
$dark-light-700: #1B1B1B;
$dark-light-800: #1C1E1F;
$dark-light-900: #1E2021;


//Dark Mode Gray
$dark-gray-100: #242528;
$dark-gray-200: #3C3E43;
$dark-gray-300: #AFB2B8;
$dark-gray-400: #969AA2;
$dark-gray-500: #7D828C;
$dark-gray-600: #656B76;
$dark-gray-700: #CCCCCC;
$dark-gray-800: #393F4D;
$dark-gray-900: #C5CCDB;


// Primary
$primary-100: lighten($primary, 40%);
$primary-200: lighten($primary, 30%);
$primary-300: lighten($primary, 20%);
$primary-400: lighten($primary, 10%);
$primary-500: $primary;
$primary-600: darken($primary, 10%);
$primary-700: darken($primary, 20%);
$primary-800: darken($primary, 30%);
$primary-900: darken($primary, 40%);

// Secondary
$secondary-100: lighten($secondary, 40%);
$secondary-200: lighten($secondary, 30%);
$secondary-300: lighten($secondary, 20%);
$secondary-400: lighten($secondary, 10%);
$secondary-500: $secondary;
$secondary-600: darken($secondary, 10%);
$secondary-700: darken($secondary, 20%);
$secondary-800: darken($secondary, 30%);
$secondary-900: darken($secondary, 40%);

// Pink
$pink-100: #FFDBEC;
$pink-200: #FECAE3;
$pink-300: #FEB8D9;
$pink-400: #FEA6D0;
$pink-500: #FE94C6;
$pink-600: #FE82BD;
$pink-700: #FD71B3;
$pink-800: #FD5FAA;
$pink-900: #FD4DA0;

// Violet
$violet-100: #F0F0FF;
$violet-200: #E1E2FF;
$violet-300: #D1D3FF;
$violet-400: #C2C5FF;
$violet-500: #B3B6FF;
$violet-600: #A4A7FF;
$violet-700: #9599FF;
$violet-800: #858AFF;
$violet-900: #767CFF;

// Light
$light-100: #FDFDFD;
$light-200: #FBFBFB;
$light-300: #F8F9F9;
$light-400: #F6F7F7;
$light-500: #F4F5F5;
$light-600: #F2F2F3;
$light-700: #F0F0F1;
$light-800: #EDEEEF;
$light-900: #EBECED;

// Gray
$gray-100: #E4E5E7;
$gray-200: #C9CBCF;
$gray-300: #AFB2B8;
$gray-400: #969AA2;
$gray-500: #7D828C;
$gray-600: #656B76;
$gray-700: #4E5561;
$gray-800: #393F4D;
$gray-900: #242B3A;

// Info
$info-100: #E1D8FB;
$info-200: #D1C4F9;
$info-300: #C2B1F7;
$info-400: #B39DF5;
$info-500: #A489F2;
$info-600: #9576F0;
$info-700: #8562EE;
$info-800: #764FEC;
$info-900: #673BEA;

// Success
$success-100: #D2F5E1;
$success-200: #BBF0D3;
$success-300: #A4EBC4;
$success-400: #8EE7B5;
$success-500: #77E2A6;
$success-600: #60DD97;
$success-700: #49D889;
$success-800: #33D37A;
$success-900: #1CCE6B;

// Danger
$danger-100: #F6CECE;
$danger-200: #F1B6B6;
$danger-300: #EC9E9E;
$danger-400: #E88686;
$danger-500: #E36D6D;
$danger-600: #DE5555;
$danger-700: #D93D3D;
$danger-800: #D52424;
$danger-900: #D00C0C;

// Warning
$warning-100: #FFF4D2;
$warning-200: #FFEEBC;
$warning-300: #FFE9A6;
$warning-400: #FFE390;
$warning-500: #FFDD79;
$warning-600: #FFD863;
$warning-700: #FFD24D;
$warning-800: #FFCD36;
$warning-900: #FFC720;

// Purple
$purple-100: #F0DEF3;
$purple-200: #E8CDED;
$purple-300: #E1BDE7;
$purple-400: #D9ACE1;
$purple-500: #D19BDB;
$purple-600: #CA8BD5;
$purple-700: #C27ACF;
$purple-800: #bb6ac9;
$purple-900: #b359c3;

// Skyblue
$skyblue-100: #D3F5FC;
$skyblue-200: #BEF1FB;
$skyblue-300: #A8ECFA;
$skyblue-400: #92E7F9;
$skyblue-500: #7CE2F7;
$skyblue-600: #66DDF6;
$skyblue-700: #51D9F5;
$skyblue-800: #3BD4F3;
$skyblue-900: #25CFF2;

$colors: (
  "skyblue":    $skyblue,
  "purple":     $purple,
  "pink":       $pink,
  "white":      $white,
  "gray":       $gray-600,
  "gray-dark":  $gray-800
);

$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "dark": $dark,
    "light": $light,
    "black": $black,
    "purple": $purple
);

$text-color: #656B76;
$title-color: #242B3A;
$sub-title: #6A7287;
$body-bg: #000;
$text-muted: #677788;
$default-background: #f7f8f9;
$black-bg: #141432;
$input-bg: #2c2c50;
$form-control-bg: #ffffff;

// Social Icons Colors
$facebook: #3B5998;
$twitter: #00ACEE;
$google: #DD4B39;
$telegram: #0088CC;
$linkedin: #0E76A8;
$youtube: #C4302B;
$instagram: #3F729B;
$reddit: #C6C6C6;
$pinterest: #cc2127;
$vk: #2B587A;
$rss: #EE802F;
$skype: #00AFF0;
$xing: #126567;
$tumblr: #34526F;
$email: #6567A5;
$delicious: #205CC0;
$stumbleupon: #F74425;
$digg: #191919;
$blogger: #FC4F08;
$flickr: #FF0084;
$vimeo: #86C9EF;
$yahoo: #720E9E;
$gplus: #DD4B39;
$appstore: #000;

// Gradient Variables
$primary-transparent: #FFECF5;
$secondary-transparent: #ECECF5;
$success-transparent: #E8FAF0;
$warning-transparent: #FFF9E9;
$danger-transparent: #FAE7E7;
$info-transparent: #F0EBFD;
$pink-transparent: #FFEDF6;
$purple-transparent: #F7EEF9;
$light-transparent: #FEFEFF;
$dark-transparent: #EAEBEE;
$skyblue-transparent: #E9FAFE;
$default-gradient:linear-gradient(93.34deg, $primary -14.27%, #FB5715 273.43%);
$primary-gradient: linear-gradient(90deg, $primary 0%, $primary-blue 100%);
$secondary-gradient: linear-gradient(90deg, #FF0098 0%, #FFD24D 53.5%, #00AAE6 100%);
$light-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.47) 0%, #FFFFFF 100%);
$success-gradient: linear-gradient(180deg, #E8F9E8 0%, #FFFFFF 100%);
$warning-gradient: linear-gradient(180deg, #FEF8EA 0%, #FFFFFF 100%);
$danger-gradient: linear-gradient(180deg, #FDE9ED 0%, #FFFFFF 100%);
$info-gradient: linear-gradient(180deg, #E7F1FC 0%, #FFFFFF 100%);
$pink-gradient: linear-gradient(180deg, #FFA795 0%, #FFFFFF 100%);
$teal-gradient: linear-gradient(180deg, #E6F9FF 0%, #FFFFFF 100%);
$dark-gradient: linear-gradient(180deg, #F3F6FF 0%, #FFFFFF 100%);
$purple-gradient: linear-gradient(to right, $purple 0%, #9b25b7 100%);
$black-gradient: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);


// Style Anchor Elements
$link-color: $primary;
$link-decoration: none;
$link-shade-percentage: 20%;
$link-hover-color: darken($link-color, 10%);
$link-hover-decoration: null;
$spacer: 1rem;

// Border
$border-width: 1px;
$border-style: solid;
$border-color: $light-900;
$default-border: $light-900;
$input-border: $light-900;

// Border Radius
$border-radius: 4px;
$border-radius-sm: 3px;
$border-radius-lg: 5px;
$border-radius-xl: 8px;
$border-radius-xxl: 12px;
$rounded: 50%;
$rounded-pill: 1.5rem;

// Box Shadow
$box-shadow:  0px 0px 5px 0px rgba(0, 0, 0, 0.08);
$box-shadow-sm: 0px 4.4px 12px -1px rgba(222, 222, 222, 0.36);
$box-shadow-md: 0px 4px 4px 0px rgba(217, 217, 217, 0.25);
$box-shadow-lg: 0px 4px 13px -2px rgba(19, 16, 34, 0.06);
$box-shadow-xl: 0px 10px 20px -5px rgba(0, 0, 0, 0.08);
$box-shadow-xs: 0px 4px 15px 0px rgba(0, 0, 0, 0.04);
$success-shadow: 0px 0px 0px 1px rgba(3, 201, 90, 1);


// Font Size
$font-size-8: 8px;
$font-size-9: 9px;
$font-size-10: 10px;
$font-size-11: 11px;
$font-size-12: 12px;
$font-size-13: 13px;
$font-size-14: 14px;
$font-size-15: 15px;
$font-size-16: 16px;
$font-size-17: 17px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-22: 22px;
$font-size-24: 24px;
$font-size-26: 26px;
$font-size-28: 28px;
$font-size-30: 30px;
$font-size-32: 32px;
$font-size-34: 34px;
$font-size-36: 36px;
$font-size-38: 38px;
$font-size-40: 40px;
$font-size-42: 42px;
$font-size-44: 44px;
$font-size-50: 50px;
$font-size-52: 52px;
$font-size-54: 54px;
$font-size-60: 60px;

$h1-font-size: 28px;
$h2-font-size: 24px;
$h3-font-size: 20px;
$h4-font-size: 18px;
$h5-font-size: 16px;
$h6-font-size: 14px;