body {
	font-family: $font-family-primary;
	font-size: $font-size-16;
	color: $text-color;
	line-height: 1.5;
	overflow-y: auto;
	overflow-x: hidden;
}
::selection {
  background: $primary;
  color: $white;
  text-shadow: none;
}
img {
	max-width: 100%;
	height: auto;
}
button {
	@include transition(all 0.5s ease);
	&:focus {
		box-shadow: none !important;
	}
}
strong {
	font-weight: $font-weight-bold;
}
h1, .h1, h2, .h2, h3, .h3, .h4, h5, .h5, h6, .h6 {
  	color: $title-color;
  	font-family: $font-family-primary;
	font-weight: $font-weight-bold;
  	margin-bottom: 0;
}

ul {
  list-style: none;
	&.list-disc {
	  list-style: disc;
	  padding-left: 15px;
	  ul {
	    list-style-type: circle;
	  }
	}
	&.list-decimal {
	  list-style: decimal;
	  padding-left: 15px;
	  ul{
	    list-style-type: decimal;
	  }
	}
	&.list-icon {
	  list-style: none;
	  padding-left: 0;
	  > li {
	    padding-left: 1.875rem;
	    counter-increment: li;
	    i,
	    .feather-icon,
	    .svg-icon {
	      display: inline-block;
	      height: 20px;
	      width: 20px;
	      text-align: center;
	      margin-left: -1.25rem;
	      position: relative;
	      left: -10px;
	      padding: 1px;
	      top: -1px;
	      
	    }
	    i {
	      font-size: $font-size-20;
	      top: 5px;
	    }
	  }
	}
	.btn {
	  &-primary {
	      &:not(:disabled):not(.disabled) {
	          &:active,
	          &.active {
	              &:focus {
	                  box-shadow: none;
	              	}
	          	}
	      	}
	  	}
	}
}
.container {
	@include respond-below(lg) {
		max-width: 100%;
	}
}
