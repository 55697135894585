.xb-cursor,
.xb-cursor-section {
 visibility:hidden;
 position:fixed;
 pointer-events:none;
 z-index:999999;
 display:flex;
 justify-content:center;
 align-items:center;
 -webkit-transition:transform .2s cubic-bezier(.165,.84,.44,1);
 -khtml-transition:transform .2s cubic-bezier(.165,.84,.44,1);
 -moz-transition:transform .2s cubic-bezier(.165,.84,.44,1);
 -ms-transition:transform .2s cubic-bezier(.165,.84,.44,1);
 -o-transition:transform .2s cubic-bezier(.165,.84,.44,1);
 transition:transform .2s cubic-bezier(.165,.84,.44,1);
 pointer-events:none;
 top:0;
 left:0
}
.xb-cursor .xb-cursor-wrapper,
.xb-cursor-section .xb-cursor-wrapper {
 display:flex;
 justify-content:center;
 align-items:center;
 -webkit-transition:transform .5s cubic-bezier(.165,.84,.44,1),opacity .5s cubic-bezier(.165,.84,.44,1);
 -khtml-transition:transform .5s cubic-bezier(.165,.84,.44,1),opacity .5s cubic-bezier(.165,.84,.44,1);
 -moz-transition:transform .5s cubic-bezier(.165,.84,.44,1),opacity .5s cubic-bezier(.165,.84,.44,1);
 -ms-transition:transform .5s cubic-bezier(.165,.84,.44,1),opacity .5s cubic-bezier(.165,.84,.44,1);
 -o-transition:transform .5s cubic-bezier(.165,.84,.44,1),opacity .5s cubic-bezier(.165,.84,.44,1);
 transition:transform .5s cubic-bezier(.165,.84,.44,1),opacity .5s cubic-bezier(.165,.84,.44,1)
}
.xb-cursor .xb-cursor--follower,
.xb-cursor-section .xb-cursor--follower {
 position:absolute;
 background-color: $primary;
 opacity:.25;
 border-radius:100%;
 width:1.5rem;
 height:1.5rem;
 -webkit-transition:transform .3s cubic-bezier(.165,.84,.44,1),opacity .3s cubic-bezier(.165,.84,.44,1),background-color .1s cubic-bezier(.165,.84,.44,1);
 -khtml-transition:transform .3s cubic-bezier(.165,.84,.44,1),opacity .3s cubic-bezier(.165,.84,.44,1),background-color .1s cubic-bezier(.165,.84,.44,1);
 -moz-transition:transform .3s cubic-bezier(.165,.84,.44,1),opacity .3s cubic-bezier(.165,.84,.44,1),background-color .1s cubic-bezier(.165,.84,.44,1);
 -ms-transition:transform .3s cubic-bezier(.165,.84,.44,1),opacity .3s cubic-bezier(.165,.84,.44,1),background-color .1s cubic-bezier(.165,.84,.44,1);
 -o-transition:transform .3s cubic-bezier(.165,.84,.44,1),opacity .3s cubic-bezier(.165,.84,.44,1),background-color .1s cubic-bezier(.165,.84,.44,1);
 transition:transform .3s cubic-bezier(.165,.84,.44,1),opacity .3s cubic-bezier(.165,.84,.44,1),background-color .1s cubic-bezier(.165,.84,.44,1)
}
.xb-cursor.style-2 .xb-cursor--follower {
    background-color: var(--color-primary-2);
}
.xb-cursor .xb-cursor--label,
.xb-cursor-section .xb-cursor--label {
 position:absolute;
 display:flex;
 justify-content:center;
 align-items:center;
 color:#fff;
 font-size:15px;
 transform:scale(0);
 will-change:transform,opacity;
 -webkit-transition:transform .5s cubic-bezier(.165,.84,.44,1),opacity .5s cubic-bezier(.165,.84,.44,1);
 -khtml-transition:transform .5s cubic-bezier(.165,.84,.44,1),opacity .5s cubic-bezier(.165,.84,.44,1);
 -moz-transition:transform .5s cubic-bezier(.165,.84,.44,1),opacity .5s cubic-bezier(.165,.84,.44,1);
 -ms-transition:transform .5s cubic-bezier(.165,.84,.44,1),opacity .5s cubic-bezier(.165,.84,.44,1);
 -o-transition:transform .5s cubic-bezier(.165,.84,.44,1),opacity .5s cubic-bezier(.165,.84,.44,1);
 transition:transform .5s cubic-bezier(.165,.84,.44,1),opacity .5s cubic-bezier(.165,.84,.44,1)
}
.xb-cursor .xb-cursor--drap,
.xb-cursor-section .xb-cursor--drap {
 line-height:87px;
 text-align:center;
 width:87px;
 height:87px;
 background-color:#000;
 font-size:0;
 -webkit-border-radius:87px;
 -khtml-border-radius:87px;
 -moz-border-radius:87px;
 -ms-border-radius:87px;
 -o-border-radius:87px;
 border-radius:87px;
 -webkit-transition:transform .5s cubic-bezier(.165,.84,.44,1),opacity .5s cubic-bezier(.165,.84,.44,1);
 -khtml-transition:transform .5s cubic-bezier(.165,.84,.44,1),opacity .5s cubic-bezier(.165,.84,.44,1);
 -moz-transition:transform .5s cubic-bezier(.165,.84,.44,1),opacity .5s cubic-bezier(.165,.84,.44,1);
 -ms-transition:transform .5s cubic-bezier(.165,.84,.44,1),opacity .5s cubic-bezier(.165,.84,.44,1);
 -o-transition:transform .5s cubic-bezier(.165,.84,.44,1),opacity .5s cubic-bezier(.165,.84,.44,1);
 transition:transform .5s cubic-bezier(.165,.84,.44,1),opacity .5s cubic-bezier(.165,.84,.44,1);
 transform:scale(0);
 will-change:transform,opacity;
 color:#fff
}
.xb-cursor .xb-cursor--drap:before,
.xb-cursor-section .xb-cursor--drap:before,
.xb-cursor .xb-cursor--drap:after,
.xb-cursor-section .xb-cursor--drap:after {
 content:"\f10b";
 font-family:caseicon;
 font-size:10px;
 display:inline-flex
}
.xb-cursor .xb-cursor--drap:before,
.xb-cursor-section .xb-cursor--drap:before {
 -webkit-transform:scaleX(-1);
 -khtml-transform:scaleX(-1);
 -moz-transform:scaleX(-1);
 -ms-transform:scaleX(-1);
 -o-transform:scaleX(-1);
 transform:scaleX(-1)
}
.xb-cursor .xb-cursor--drap:after,

.xb-cursor.is-enabled,
.xb-cursor-section.is-enabled {
 visibility:visible
}
.xb-cursor.is-hidden .xb-cursor-wrapper,
.xb-cursor-section.is-hidden .xb-cursor-wrapper {
 transform:scale(0)!important
}
.xb-cursor.is-mouse-down .xb-cursor-wrapper,
.xb-cursor-section.is-mouse-down .xb-cursor-wrapper {
 transform:scale(.8)
}
.xb-cursor.is-active .xb-cursor--follower,
.xb-cursor-section.is-active .xb-cursor--follower {
 -webkit-box-shadow:0 0 8px 0 rgba(0,0,0,.12);
 -khtml-box-shadow:0 0 8px 0 rgba(0,0,0,.12);
 -moz-box-shadow:0 0 8px 0 rgba(0,0,0,.12);
 -ms-box-shadow:0 0 8px 0 rgba(0,0,0,.12);
 -o-box-shadow:0 0 8px 0 rgba(0,0,0,.12);
 box-shadow:0 0 8px rgba(0,0,0,.12);
 @include transform(scale(4));
 opacity:0
}
