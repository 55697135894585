.rating {
    i {
        &.filled {
            color: $warning;
        }
    }
}
.card {
    .card-img {
        border-radius: 5px;
        img {
            border-radius: 5px;
            transition: 0.5s all;
        }
    }
    .card-img-hover {
        position: relative;
        overflow: hidden;
        transition: 0.5s all;
        &:hover {
            img {
                transform: scale(1.14);
                transition: 0.5s all;
            }
        }
    }
}
.provider-detail {
    .provider-pic {
        width: 120px;
        height: 120px;
        img {
            border-radius: 5px;
        }
    }
}
.provider-bio-info {
    h6 {
        font-size: $font-size-16;
        font-weight: $font-weight-medium;
    }
}
.area-expert {
    background: $light-300;
    border-radius: 5px;
    padding: 15px 10px;
}
.team-member {
    border: 1px solid $light-900;
    border-radius: 5px;
    padding: 10px;
}
.custom-owl-dot {
    &.owl-carousel {
        .owl-nav {
            button {
                width: 26px;
                height: 26px;
                border-radius: 50%;
                background: $white;
                display: flex;
                align-items: center;
                justify-content: center;
                top: 50%;
                transform: translateY(-50%);
                position: absolute;
                box-shadow: 0px 4px 14px 0px #8D8D8D40;
                font-size: $font-size-14;
                &:hover {
                    background: $primary;
                    color: $white;
                }
                &.owl-prev {
                    left: -10px;
                }
                &.owl-next {
                    right: -10px;

                }
            }
        }
    }
}
.benifits-item {
    border: 1px solid $light-900;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 15px;
}
#filter_inputs {
    display: none;
}
.prov-icon {
    width: 34px;
    height: 34px;
    i {
        font-size: $font-size-24;
    }
}
.our-services-slider {
    .img-sec {
        position: relative;
        transition: 0.5s all;
        overflow: hidden;
        img {
            transition: 0.5s all;
        }
        &:hover {
            img {
                transition: 0.5s all;
                transform: scale(1.14);
            }
        }
    }
}