.mean-container {
    a.meanmenu-reveal {
        display: none !important;
    }
    .mean-bar {
        background: $white;
    }
    .mean-nav {
        background: $white;
        margin-top: 0;
        & > ul {
            display: block !important;
            li {
                a {
                    color: $gray-900;
                }
            }
        }
    }
}