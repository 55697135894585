.floating-whatsapp-button {
    position: fixed;
    bottom: 20px;
    /* Distancia desde el borde inferior */
    right: 20px;
    /* Distancia desde el borde derecho */
    background-color: #25d366;
    /* Color de WhatsApp */
    color: white;
    border: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    z-index: 1000;
}

.floating-whatsapp-button:hover {
    background-color: #20b458;
    /* Color más oscuro al pasar el cursor */
}

.floating-whatsapp-button i {
    font-size: 24px;
    /* Icono de WhatsApp */
}