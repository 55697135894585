.about-banner {
    // background: url(../../../../public/assets/img/bg/about-bg.png);
    margin-bottom: 47px;
}

.quality-box {
    background: #F7F7FF;
    border: 2px solid #F5F5F5;
    border-radius: 8px;
    padding: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 24px;

    &:hover {
        border: 2px solid $dark;
        box-shadow: 0px 10px 20px -5px rgba(76, 64, 237, 0.08);

        .quality-img {
            background: $dark;

            img {
                filter: brightness(0) invert(1);
            }
        }
    }
}

.quality-img {
    width: 58px;
    height: 58px;
    background: #fff;
    margin-right: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    border-radius: 50%;
    flex-shrink: 0;

    img {
        width: 28px;
    }
}

.quality-info {
    h6 {
        font-weight: 500;
        margin-bottom: 10px;
    }

    p {
        font-size: 14px;
        margin-bottom: 0;
    }
}

.about-sec {
    padding: 80px 0;
    @include respond-below(md) {
        padding: 40px 0;
    }
}

.about-img {
    position: relative;

    img {
        border-radius: 10px;
    }

    .about-exp {
        span {
            font-weight: 600;
            font-size: 24px;
            color: $white;
            display: inline-block;
            margin: 200px -115px;
            transform: rotate(-90deg);
        }

        position: absolute;
        background: $dark;
        border-radius: 10px;
        width: 370px;
        height: 100%;
    }
}

.abt-img {
    padding: 30px 0 30px 60px;
    position: relative;
    z-index: 1;
}

.about-content {
    margin-left: 24px;
    @include respond-below(md) {
        margin-left: 0;
        margin-top: 24px;
    }
    @include respond-below(sm) {
        margin-top: 0;
    }
    h6 {
        font-size: 16px;
        color: $dark;
        text-transform: uppercase;
        margin-bottom: 4px;
    }

    h2 {
        font-size: 32px;
        margin-bottom: 16px;
    }

    p {
        font-size: 14px;
        margin-bottom: 16px;
    }

    ul {
        li {
            display: flex;
            align-items: center;
            font-size: 14px;
            margin-bottom: 15px;
        }
    }
}

.bgimg4 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.work-bg.work-section {
    background: $light-400;
    background-repeat: no-repeat;
    background-position: left 0 bottom 20%;
    position: relative;

    @include respond-below(md) {
        background: none;
    }

    .work-bg-2 {
        position: absolute;
        right: 0;
        bottom: 15%;
    }
    .work-bg-1 {
        position: absolute;
        left: 0;
        top: 100px0;
    }

    .section-heading {
        margin-bottom: 40px;
    }

    .work-box {
        background: $white;
        border: 1px solid $light-900;
        box-shadow: 0px 10px 20px -5px rgba(76, 64, 237, 0.08);
        border-radius: 10px;
        padding: 20px;
        text-align: center;
        margin-bottom: 24px;
        position: relative;

        .card-body {
            h5 {
                font-size: 20px;
                margin-bottom: 8px;
            }

            p {
                font-size: 14px;
                color: $gray-600;
                margin-bottom: 0;
            }
            h4 {
                font-weight: 600;
                font-size: 60px;
                color: $primary-transparent;
                margin-bottom: 0;
                position: absolute;
                top: -30px;
                left: 0;
            }
        }
    }
}

.providers-section.abt-provider {
    padding: 0 0 56px;
    position: relative;

    .providers-slider {
        .owl-nav {

            .owl-prev {
                display: flex;
                align-items: center;
                position: absolute;
                right: 10%;
                top: -18%;

                @include respond-below(md) {
                    right: 18%;
                }

                i {
                    width: 32px;
                    height: 32px;
                    background-color: $white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;

                    &:hover {
                        background-color: $dark;
                        color: $white;
                    }
                }

            }

            .owl-next {
                display: flex;
                align-items: center;
                position: absolute;
                position: absolute;
                right: 7%;
                top: -18%;

                i {
                    width: 32px;
                    height: 32px;
                    background-color: $white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;

                    &:hover {
                        background-color: $dark;
                        color: $white;
                    }
                }
            }
        }
    }
}

.chooseus-sec {
    padding: 80px 0 60px;
    background: $white;
    .choose-content {
        .accordion {
            .accordion-item {
                margin-bottom: 20px;

                .accordion-header {
                    background-color: $light-400;
                    border-radius: 5px;

                    .accordion-button {
                        border-radius: 5px;
                        font-size: 18px;

                        &.collapsed {
                            background-color: $light-400;
                        }
                    }
                }

                .accordion-body {
                    margin-top: 15px;
                    border: none;

                    p {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

.choose-content {
    h2 {
        font-size: 32px;
    }

    p {
        font-size: 14;
        margin-bottom: 40px;
        color: $gray-600;
    }
    margin-bottom: 50px;
}

.choose-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 20px;
    border-right: 1px dashed $primary;

    img {
        margin-right: 20px;
    }
}

.choose-info {

    p {
        font-size: 16px;
        margin-bottom: 0;
        color: $gray-600;
    }
}

.chooseus-img {
    margin-bottom: 50px;
}

.support-card {
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }

    p {
        margin: 15px 0 0;
    }

    .support-title {
        background: $light-400;
        border-radius: 5px;
        padding: 20px;
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 0;

        a {
            color: #28283C;
            display: block;
            position: relative;
            padding-right: 60px;
        }

        >a {
            &:after {
                font-family: "Fontawesome";
                font-weight: 900;
                font-size: 15px;
                content: "\f078";
                position: absolute;
                top: 0;
                right: 0;
                color: #292D32;
            }
        }
    }

    .card-collapse {
        p {
            font-size: 14px;
        }
    }
}



.about-offer {
    padding: 0 67px;
    border-radius: 20px;
    margin: 0;
    align-items: flex-end;

    .offer-path-content {
        padding: 67px 42px 67px 0;

        h3 {
            margin-bottom: 25px;
        }
    }

    .offer-pathimg {
        margin-top: 0;
    }

    .btn-views {
        min-width: 138px;
        color: $dark;
    }
}

.about-offer.offer-paths {
    &::after {
        content: none;
    }
}



//Provider Section
.providers-section {
    .section-heading {
        margin-bottom: 40px;
    }

    .providerset {
        .card-body {
            padding: 18px;

            .providerset-img {
                margin-bottom: 18px;

                a {
                    img {
                        border-radius: 10px;
                    }
                }
            }

            .providerset-content {
                .providerset-price {
                    margin-bottom: 15px;

                    .providerset-name {
                        h4 {
                            font-size: 18px;
                        }

                        span {
                            font-size: 14px;
                        }
                    }

                    .providerset-prices {
                        span {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}

.client-section {
    background: url(../../../../public/assets/img/bg/testimonial-bg-01.png), url(../../../../public/assets/img/bg/testimonial-bg-02.png), $light-400;
    background-repeat: no-repeat;
    background-position: left center, right bottom;
    position: relative;

    .overlay-img {
        .overlay-img-left {
            position: absolute;
            left: 0;
            top: 10%;
        }

        .overlay-img-right {
            position: absolute;
            right: 0;
            bottom: 10%;
        }
    }

    .section-heading {
        margin-bottom: 40px;

        p {
            font-size: 16px;
        }
    }

    .testimonial-slider {
        .client-widget {
            background: $white;
            box-shadow: 0px 10px 20px -5px rgba(76, 64, 237, 0.08);
            padding: 1.5rem;
            text-align: center;
            margin: 60px 0px 24px;
            border-radius: 10px;

            .client-img {
                margin: -73px auto 20px;
                width: 110px !important;
                height: 110px;

                @include respond-below(md) {
                    margin: -83px 60px 20px;
                }
            }

            .client-content {
                p {
                    font-size: 14px;
                }

                h5 {
                    font-size: 16px;
                    color: $gray-900;
                }

                h6 {
                    font-size: 13px;
                    color: $gray-600;
                }
            }
        }

        .owl-nav {
            .owl-prev {
                display: flex;
                align-items: center;
                position: absolute;
                left: -1%;
                top: 50%;

                @include respond-below(md) {
                    left: -2%;
                }

                i {
                    padding: 8px;
                    background-color: $white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;

                    &:hover {
                        background-color: $primary;
                        color: $white;
                    }
                }
            }

            .owl-next {
                display: flex;
                align-items: center;
                position: absolute;
                position: absolute;
                right: -1%;
                top: 50%;

                @include respond-below(md) {
                    right: -2%;
                }

                i {
                    padding: 8px;
                    background-color: $white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;

                    &:hover {
                        background-color: $primary;
                        color: $white;
                    }
                }
            }
        }

    }

}

//Service
.service-offer {
    position: relative;
    margin-top: -120px;
    &.about-service-offer {
        &::before {
            display: none;
        }
    }

    .card {
        background-color: $dark;
        overflow: hidden;

        .card-body {
            padding: 60px 60px 0;
            position: relative;
            @include respond-below(lg) {
                padding: 40px 20px;
            }
            .service-content {
                padding: 0;
                .display-6 {
                    color: $white;
                    margin-bottom: 25px;
                }

                p {
                    color: $white;
                    font-size: 14px;
                    margin-bottom: 25px;
                }
            }

            .service-img {
                @include respond-below(lg) {
                    display: none;
                }
            }
        }
    }
}

//Contact Details
.contacts {
    position: relative;

    .contacts-overlay-img {
        position: absolute;
        right: -10%;
        top: -20%;
        z-index: -1;
    }

    .contacts-overlay-sm {
        position: absolute;
        top: 70%;
        left: 40%;
        z-index: -1;
    }

    .contact-details {
        margin-bottom: 60px;

        .card {
            .card-body {
                padding: 15px;

                span {
                    width: 70px;
                    height: 70px;
                    flex-shrink: 0;
                    background-color: $primary-transparent;
                    margin-right: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 30px;
                }

                p {
                    color: $gray-600;
                    margin-bottom: 0;
                }
            }
        }

    }

    .contact-queries {
        h2 {
            margin-bottom: 40px;
        }

        .submit-btn {
            margin-top: 40px;
        }
    }


}

.map-grid {

    .contact-map {
        display: block;
        width: 100%;
        height: 450px;
        margin: 0;
    }
}

//How It Works
.work-wrap {
    max-width: 550px;

    span {
        width: 50px;
        height: 50px;
        background: $primary;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: $white;
        font-size: 24px;
        margin-bottom: 16px;
    }

    .display-6 {
        margin-bottom: 16px;
    }

    p {
        font-size: 14px;
        margin-bottom: 16px;
    }
}

.work-wrap-img {
    max-width: 300px;
}

.work-wrap-post {
    max-width: 550px;

    .display-6 {
        margin-bottom: 16px;
    }

    p {
        font-size: 14px;
        margin-bottom: 16px;
    }
}

.work-wrap-earning {
    max-width: 550px;

    .display-6 {
        margin-bottom: 16px;
    }

    p {
        font-size: 14px;
        margin-bottom: 16px;
    }
}

//Error-404
.error-404 {
    position: relative;
    z-index: 1;

    .contacts-overlay-img {
        position: absolute;
        right: 0;
        top: -30%;
        z-index: -1;
        max-width: 500px;
    }
}

//Faq
.faq-content {
    margin-bottom: 24px;

    .faq {
        .accordion {
            .accordion-item {
                margin-bottom: 20px;

                .accordion-header {

                    button {
                        background-color: $light-300;
                        font-size: 16px;

                        &:not(.collapsed) {
                            color: $primary;
                        }
                    }
                }

                .accordion-body {
                    margin-bottom: 20px;
                    border: none;
                    background-color: $light-300;
                    border-radius: 5px;

                    p {
                        font-size: 16px;
                    }
                }
            }

            .accordion-button:not(.collapsed)::after {
                content: "\f056";
                color: $primary;
            }

            .accordion-button:after {
                content: "\f055";
                font-family: "Fontawesome";
                font-size: 16px;
                line-height: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 3.125rem;
                color: #656B76;
            }
        }

    }
}

//Maintenance
.maintenance {
    .maintenance-title {
        margin-bottom: 16px;
    }

    .maintenance-icons {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        h6 {
            margin-right: 16px;
        }

        .icons {
            display: flex;
            align-items: center;
            gap: 11px;
        }
    }
}

//privacy policy
.privacy-cont {
    p {
        font-size: 14px;
        margin-bottom: 16px;
        &:last-child {
            margin-bottom: 0;
        }
    }

    ul {
        margin-bottom: 16px;
        list-style-type: disc !important;

        li {
            margin-left: 25px;
            align-items: center;
            font-size: 14px;
        }
    }

    .terms-btn {
        display: flex;
        align-items: center;
    }
}

//Coming Soon
.footer-copyright {
    .maintenance-icons {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        h6 {
            margin-right: 16px;
        }

        .icons {
            display: flex;
            align-items: center;
            gap: 11px;
        }
    }
}

.client-section.client-section-about {
	padding-bottom: 250px;
    background-color: #F6F7F7;
    background-size: cover;
}
.testimonial-slider-3 {
    .owl-nav {
        button {
            position: absolute;
            top: 50% !important;
            transform: translateY(-50%);
            &.owl-prev {
                left: -10px;
            }
            &.owl-next {
                right: -10px;
            }
        }
    }
}
.service-offer.about-service-offer {
    padding-bottom: 50px;
}
.contact-img {
    margin-right: 24px;
    @include respond-below(md) {
        margin-right: 0;
        margin-bottom: 24px;
    }
}