.border-container {
    display: inline-block;
    width: 5rem;
    height: 5rem;
    margin: 0.25rem;
    background-color: $light;
}
.border {
    border: 1px solid $border-color !important;
    &.border-dashed {
        border-style: dashed !important;
    }
    &.border-dotted {
        border-style: dotted !important;
    }
    &.border-primary {
        border: 1px solid $primary !important;
        opacity: 1;
        &.border-opacity-10 {
            border-color: rgba($primary, 0.1) !important;
        }
        &.border-opacity-25 {
            border-color: rgba($primary, 0.25) !important;
        }
        &.border-opacity-50 {
            border-color: rgba($primary, 0.50) !important;
        }
        &.border-opacity-75 {
            border-color: rgba($primary, 0.75) !important;
        }
        &.border-opacity-100 {
            border-color: rgba($primary,1) !important;
        }
    }
    &.border-secondary {
        border: 1px solid $secondary !important;
        opacity: 1;
        &.border-opacity-10 {
            border-color: rgba($secondary,0.1) !important;
        }
        &.border-opacity-25 {
            border-color: rgba($secondary,0.25) !important;
        }
        &.border-opacity-50 {
            border-color: rgba($secondary,0.50) !important;
        }
        &.border-opacity-75 {
            border-color: rgba($secondary,0.75) !important;
        }
        &.border-opacity-100 {
            border-color: rgba($secondary,1) !important;
        }
    }
    &.border-info {
        border: 1px solid $info !important;
        opacity: 1;
        &.border-opacity-10 {
            border-color: rgba($info,0.1) !important;
        }
        &.border-opacity-25 {
            border-color: rgba($info,0.25) !important;
        }
        &.border-opacity-50 {
            border-color: rgba($info,0.50) !important;
        }
        &.border-opacity-75 {
            border-color: rgba($info,0.75) !important;
        }
        &.border-opacity-100 {
            border-color: rgba($info,1) !important;
        }
    }
    &.border-success {
        border: 1px solid $success !important;
        opacity: 1;
        &.border-opacity-10 {
            border-color: rgba($success,0.1) !important;
        }
        &.border-opacity-25 {
            border-color: rgba($success,0.25) !important;
        }
        &.border-opacity-50 {
            border-color: rgba($success,0.50) !important;
        }
        &.border-opacity-75 {
            border-color: rgba($success,0.75) !important;
        }
        &.border-opacity-100 {
            border-color: rgba($success,1) !important;
        }
    }
    &.border-warning {
        border: 1px solid $warning !important;
        opacity: 1;
        &.border-opacity-10 {
            border-color: rgba($warning,0.1) !important;
        }
        &.border-opacity-25 {
            border-color: rgba($warning,0.25) !important;
        }
        &.border-opacity-50 {
            border-color: rgba($warning,0.50) !important;
        }
        &.border-opacity-75 {
            border-color: rgba($warning,0.75) !important;
        }
        &.border-opacity-100 {
            border-color: rgba($warning,1) !important;
        }
    }
    &.border-danger {
        border: 1px solid $danger !important;
        opacity: 1;
        &.border-opacity-10 {
            border-color: rgba($danger,0.1) !important;
        }
        &.border-opacity-25 {
            border-color: rgba($danger,0.25) !important;
        }
        &.border-opacity-50 {
            border-color: rgba($danger,0.50) !important;
        }
        &.border-opacity-75 {
            border-color: rgba($danger,0.75) !important;
        }
        &.border-opacity-100 {
            border-color: rgba($danger,1) !important;
        }
    }
    &.border-white {
        border: 1px solid $white !important;
        opacity: 1;
        &.border-opacity-10 {
            border-color: rgba($white,0.1) !important;
        }
        &.border-opacity-25 {
            border-color: rgba($white,0.25) !important;
        }
        &.border-opacity-50 {
            border-color: rgba($white,0.50) !important;
        }
        &.border-opacity-75 {
            border-color: rgba($white,0.75) !important;
        }
        &.border-opacity-100 {
            border-color: rgba($white,1) !important;
        }
    }
    &.border-light {
        border: 1px solid $light !important;
        opacity: 1;
        &.border-opacity-10 {
            border-color: rgba($light,0.1) !important;
        }
        &.border-opacity-25 {
            border-color: rgba($light,0.25) !important;
        }
        &.border-opacity-50 {
            border-color: rgba($light,0.50) !important;
        }
        &.border-opacity-75 {
            border-color: rgba($light,0.75) !important;
        }
        &.border-opacity-100 {
            border-color: rgba($light,1) !important;
        }
    }
    &.border-dark {
        border: 1px solid $dark !important;
        opacity: 1;
        &.border-opacity-10 {
            border-color: rgba($dark,0.1) !important;
        }
        &.border-opacity-25 {
            border-color: rgba($dark,0.25) !important;
        }
        &.border-opacity-50 {
            border-color: rgba($dark,0.50) !important;
        }
        &.border-opacity-75 {
            border-color: rgba($dark,0.75) !important;
        }
        &.border-opacity-100 {
            border-color: rgba($dark,1) !important;
        }
    }
    &.border-opacity-10 {
        --bs-border-opacity: 0.1 !important;
    }
    &.border-opacity-25 {
        --bs-border-opacity: 0.25 !important;
    }
    &.border-opacity-50 {
        --bs-border-opacity: 0.5 !important;
    }
    &.border-opacity-75 {
        --bs-border-opacity: 0.75 !important;
    }
    &.border-opacity-100 {
        --bs-border-opacity: 1 !important;
    }
    &.border-1 {
        border-width: 1px !important;
    }
    &.border-2 {
        border-width: 2px !important;
    }
    &.border-3 {
        border-width: 3px !important;
    }
    &.border-4 {
        border-width: 4px !important;
    }
    &.border-5 {
        border-width: 5px !important;
    }
    &.border-block-start-dashed {
        border-top-style: dashed;
    }
    &.border-block-start-dotted {
        border-top-style: dotted;
    }
    &.border-inline-end-dashed {
        border-inline-end-style: dashed;
    }
    &.border-inline-end-dotted {
        border-inline-end-style: dotted;
    }
    &.border-block-end-dashed {
        border-bottom-style: dashed;
    }
    &.border-block-end-dotted {
        border-bottom-style: dotted;
    }
    &.border-inline-start-dashed {
        border-inline-start-style: dashed;
    }
    &.border-inline-start-dotted {
        border-inline-start-style: dotted;
    }
    &.border-0 {
        border: 0 !important;
    }
    &.border-top-0 {
        border-block-start: 0 !important;
    }
    &.border-end-0 {
        border-inline-end: 0 !important;
    }
    &.border-bottom-0 {
        border-block-end: 0 !important;
    }
    &.border-start-0 {
        border-inline-start: 0 !important;
    }
}
.border-top {
    border-top: 1px solid $border-color !important;
    &.border-block-start-dashed {
        border-top-style: dashed !important;
    }
    &.border-block-start-dotted {
        border-top-style: dotted !important;
    }
    &.border-primary {
        border-color: $primary !important;
    }
    &.border-secondary {
        border-color: $secondary !important;
    }
    &.border-warning {
        border-color: $warning !important;
    }
    &.border-info {
        border-color: $info !important;
    }
    &.border-success {
        border-color: $success !important;
    }
    &.border-danger {
        border-color: $danger !important;
    }
    &.border-light {
        border-color: $light !important;
    }
    &.border-dark {
        border-color: $dark !important;
    }
}
.border-end {   
    border-inline-end: 1px solid $border-color !important;
    &.border-inline-end-dashed {
        border-inline-end-style: dashed !important;
    }
    &.border-inline-end-dotted {
        border-inline-end-style: dotted !important;
    }
    &.border-primary {
        border-color: $primary !important;
    }
    &.border-secondary {
        border-color: $secondary !important;
    }
    &.border-warning {
        border-color: $warning !important;
    }
    &.border-info {
        border-color: $info !important;
    }
    &.border-success {
        border-color: $success !important;
    }
    &.border-danger {
        border-color: $danger !important;
    }
    &.border-light {
        border-color: $light !important;
    }
    &.border-dark {
        border-color: $dark !important;
    }
}
.border-bottom {
    border-bottom: 1px solid $border-color !important;
    &.border-block-end-dashed {
        border-bottom-style: dashed !important;
    }
    &.border-block-end-dotted {
        border-bottom-style: dotted !important;
    }
    &.border-primary {
        border-color: $primary !important;
    }
    &.border-secondary {
        border-color: $secondary !important;
    }
    &.border-warning {
        border-color: $warning !important;
    }
    &.border-info {
        border-color: $info !important;
    }
    &.border-success {
        border-color: $success !important;
    }
    &.border-danger {
        border-color: $danger !important;
    }
    &.border-light {
        border-color: $light !important;
    }
    &.border-dark {
        border-color: $dark !important;
    }
    &.border-skyblue {
        border-color: $skyblue !important;
    }
}
.border-start {
    border-inline-start: 1px solid $border-color !important;
    &.border-inline-start-dashed {
        border-inline-start-style: dashed !important;
    }
    &.border-inline-start-dotted {
        border-inline-start-style: dotted !important;
    }
    &.border-primary {
        border-color: $primary !important;
    }
    &.border-secondary {
        border-color: $secondary !important;
    }
    &.border-warning {
        border-color: $warning !important;
    }
    &.border-info {
        border-color: $info !important;
    }
    &.border-success {
        border-color: $success !important;
    }
    &.border-danger {
        border-color: $danger !important;
    }
    &.border-light {
        border-color: $light !important;
    }
    &.border-dark {
        border-color: $dark !important;
    }
}
.border-primary {
    border-color: $primary !important;
}
.border-secondary {
    border-color: $secondary !important;
}
.border-warning {
    border-color: $warning !important;
}
.border-info {
    border-color: $info !important;
}
.border-success {
    border-color: $success !important;
}
.border-danger {
    border-color: $danger !important;
}
.border-skyblue {
    border-color: $skyblue !important;
}
.border-3 {
    border-width: 3px !important;
}
.br-1 {
    border-radius: 0.0625rem;
}
.br-2 {
    border-radius: 0.125rem;
}
.br-3 {
    border-radius: 0.1875rem;
}
.br-4 {
    border-radius: 0.25rem;
}
.br-5 {
    border-radius: 0.3125rem !important;
}
.br-6 {
    border-radius: 0.375rem;
}
.br-7 {
    border-radius: 0.4375rem;
}
.br-8 {
    border-radius: 0.5rem;
}
.br-9 {
    border-radius: 0.5625rem;
}
.br-10 {
    border-radius: 0.625rem !important;
}
.br-15 {
    border-radius: 0.9375rem;
}
.br-20 {
    border-radius: 1.25rem;
}
.rounded {
    border-radius: $border-radius !important;
}

