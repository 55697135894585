.badge {
    &.badge-primary {
        background: rgba($primary, 0.1);
        box-shadow: 0 1px 1px rgba($primary, 0.5);
        color: $primary;
        
    } 
    &.badge-dark-transparent {
        background: $dark-transparent;
        color: $gray-900;        
    }
    &.badge-info-transparent {
        background: $info-transparent;
        color: $info-900;        
    }
    &.badge-purple-transparent {
        background: $purple-transparent;
        color: $purple;        
    }
    &.badge-success-100 {
        background: $success-100;
        color: $success;        
    }
    &.badge-primary-transparent {
        background: $primary-transparent;
        color: $primary;        
    }
    &.badge-danger-transparent {
        background: $danger-transparent;
        color: $danger;        
    }
    &.badge-skyblue {
        background: $skyblue-transparent;
        color: $skyblue;        
    }
}
@each $color, $value in $theme-colors {
    .badge {
        &.badge-#{$color} {
            background: #{$value};
            color: $white;
        }
        &.badge-soft-#{$color} {
            background: rgba($value, 0.1);
            color: $value;
        }
    }
}
.badge{
    padding: .25rem .45rem;
    text-transform:capitalize;
    font-weight: $font-weight-semibold;
    letter-spacing: 0.5px;
    border-radius: $border-radius;
    &.badge-xs {
        font-size: 10px;
        padding: 0px 5px;
        line-height: 18px;
    }
    &.badge-sm {
        font-size: 11px;
        padding: 5px 8px;
        line-height: 11px;
    }
    &.badge-md {
        font-size: 12px;
        padding: 5px 12px;
        line-height: 1.5;
    }
    &.badge-lg {
        font-size: $font-size-14;
        padding: 0px 10px;
        line-height: 30px;
    }
    &.badge-xl {
        font-size: $font-size-16;
        padding: 0px 15px;
        line-height: 35px;
    }
    &.badge-soft-skyblue {
        background: $skyblue-100;
        color: $skyblue;
    }
}