.wallet-modal {
    .modal-body {
        padding: 20px;
        border: 1px solid $light-900;

        .bank-selection {
            position: relative;

            input {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                opacity: 0;

                &:checked {

                    ~label {
                        .role-check {
                            display: block;
                        }
                    }
                }
            }

            label {
                background: $light-900;
                border: 2px solid $light-900;
                border-radius: 8px;
                padding: 5px;
                text-align: center;
                width: 100%;
                position: relative;
                height: 42px;
                margin-top: 20px;
                margin-bottom: 20px;

                .role-check {
                    position: absolute;
                    top: -8px;
                    right: -8px;
                    color: $success;
                    display: none;
                }
            }


        }

    }

    .modal-footer {
        border: none;
    }
}

.slot-box {
    border-radius: 8px;
    background: $light-500;
    padding: 20px;
    margin-bottom: 30px;

    .slot-booked {
        h6 {
            margin-bottom: 15px;
        }
    }

    .slot-user {
        h6 {
            margin-bottom: 15px;
        }

        .slot-chat {
            display: flex;

            .slot-user-img {
                margin-right: 14px;
                @include respond-below(sm) {
                    flex-wrap: wrap;
                }
            }

            .chat-item {
                .slot-user-info {
                    margin-right: 25px;
                }
                @include respond-below(sm) {
                    flex-wrap: wrap;
                }
            }
        }
    }

    .slot-action {
        h6 {
            margin-bottom: 15px;
        }
    }
}

.order-title {
    margin-bottom: 24px;
}

.service-location {
    margin-bottom: 30px;

    .slot-address {

        li {
            display: flex;
            align-items: center;
            margin-bottom: 15px;

            h6 {
                font-size: 14px;
            }

            p {
                font-size: 13px;
                margin-bottom: 0;
            }

            span {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 11px;
                background-color: $light-900;
                border-radius: 50%;
                margin-right: 10px;
            }
        }
    }

    .slot-pay {
        display: flex;
        align-items: center;

        p {
            font-size: 18px;
            color: $gray-900;
            font-weight: 600;
            margin-bottom: 0;
            margin-right: 24px;
        }

        span {
            img {
                background-color: #F7F7FF;
                border-radius: 3px;
                padding: 6px 2px 6px 2px;
            }
        }
    }
}

.order-summary {
    margin-bottom: 30px;

    .ord-summary {
        .order-amt {
            display: flex;
            justify-content: space-between;
            margin-bottom: 21px;

            .order-info {
                display: flex;
                align-items: center;

                img {
                    margin-right: 10px;
                }

                h6 {
                    font-size: 16px;
                    font-weight: 500;
                    margin-bottom: 5px;
                }

                p {
                    font-size: 12px;

                }
            }
        }

        ul {
            li {
                display: flex;
                justify-content: space-between;
                font-size: 14px;
                font-weight: 500;
                color: $gray-900;
                margin-bottom: 13px;
            }
        }
    }
}


//Booking
.booking {
    border-top: 1px solid $light-900;
    padding-top: 30px;

    .book-history {
        ul {
            li {
                position: relative;
                padding: 0 0 24px 20px;
                margin-left: 4px;
                border-left: 2px solid #F5F5F5;
                h6{
                    font-size: 14px;
                }
                p{
                    font-size: 13px;
                    color: $gray-600;
                }

                ::after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 5px;
                    left: -4px;
                    width: 7px;
                    height: 7px;
                    background: $white;
                    outline: 4px solid $primary;
                    border-radius: 50%;

                }

            }

            :last-child {
                border: 0;
                padding-bottom: 0;
            }
        }

    }
}

//Reviews
.order-reviews{
    .order-title{
        margin-bottom: 0;
    }
    .order-comment{
        .rating{
            margin-bottom: 15px;
        }
        h6{
            font-size: 14px;
            margin-bottom: 8px;
            color: $gray-600;
        }
        p{
            font-size: 13px;
        }
    }
    
}

//Invoice
.invoice-details{
    margin-bottom: 35px;
}

.invoice-wrap{
    .invoice-address{
        margin-bottom: 30px;
        ul{
            li{
                font-size: 13px;
                margin-bottom: 9px;
            }
        }
    }
}
//Tabel
.table-resposnive{
    margin-bottom: 30px;
}
    .invoice-payment{
        ul{
            li{
                font-size: 14px;
                color: $gray-600;
                margin-bottom: 9px;
            }
        }
    }
    .invoice-total{
        ul{
            li{
                font-size: 14px;
                color: $gray-900;
                margin-bottom: 11px;
                span{
                    font-size: 14px;
                    color: $gray-600;
                }
            }
        }
    }

    //Invoice Terms
    .invoice-terms{
        margin-top: 30px;
        padding: 20px;
        border: 1px solid $light-900;
        ul{
            li{
                position: relative;
                font-size: 14px;
                margin-bottom: 11px;
                padding-left: 10px;
                &::before{
                    position: absolute;
                    content: '\f111';
                    font-family: 'FontAwesome';
                    left: 0;
                    top: 7px;
                    color: $primary;
                    font-size: 6px;
                   
                }
            }
        }
    }