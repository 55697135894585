.hero-section {
    width: 100%;
    .hero-content {
        position: relative;
        width: 100% !important;
        max-width: 100% !important;
        z-index: 1;
        background-color: $dark-secondary;
        .hero-action {
            position: absolute;
            top: 0;
            max-width: 100% !important;
            height: 100%;
            flex-direction: column;
            justify-content: center;
            z-index: 1000;
            // background-color: rgba(0, 0, 0, 0.685);
            .action-content {
                padding: 80px;
                color: #fff;
                h1 {
                    font-size: 4rem;
                    color: #fff !important;
                    @media (max-width: 1200px) {
                        font-size: 3.5rem;
                    }

                    @media (max-width: 768px) {
                        font-size: 3rem;
                    }

                    @media (max-width: 580px) {
                        font-size: 2rem;
                    }

                }
                p {
                    font-size: 16px;
                    @include respond-below(lg) {
                        font-size: 14px !important;
                    }
                }

                .btn {
                    border-radius: 50px;
                    padding: 2px 20px !important;
                    font-size: 29px;
                    @include respond-below(lg) {
                        font-size: 20px !important;
                    }
                }
                @include respond-below(lg) {
                    font-size: 15px !important;
                    padding: 25px;
                    justify-content: end;
                }
            }

            @include respond-below(lg) {
                padding: 0px 0;
                justify-content: end !important;
            }
        }
        .hero-image {
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            max-height: 800px;
            aspect-ratio: 17 / 7;
            position: relative;
            overflow: hidden;
            object-fit: cover;

            @media (max-width: 700px) {
                aspect-ratio: 1 / 1;
            }
              @media (max-width: 480px) {
                aspect-ratio: 1 / 1;
            }
            .shape-03 {
                width: 100%;
                border-radius: 0;
                max-height: 800px;
                height: auto;
                object-fit: cover;
                top: 0;
                z-index: -1;
                @include respond-below(lg) {
                height: 100%;
                object-fit: cover;
                }
            }
            .hero-loading {
                width: 100%;
                max-height: 800px;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
              }
        }
    }
    .banner-img {
        position: absolute;
        right: 10px;
        bottom: 0;
        background: url("../../../../public/assets/img/bg/banner-bg.png") no-repeat;
        background-size: contain;
        background-position: bottom center;
        @include respond-below(xxl) {
            max-width: 650px;
        }
        @include respond-below(xl) {
            max-width: 600px;
        }
        @include respond-below(lg) {
            display: none;
        }
    }
    .badge {
        @include rounded(60px);
        padding: 6px 8px;
        &:hover {
            background: $primary;
            color: $white;
        }
    }
    .banner-info {
        margin-top: 38px;
        p {
            font-size: $font-size-14;
        }
        @include respond-below(lg) {
            margin-top: 18px;
        }
    }
    .banner-form {
        position: relative;
        @include rounded(8px);
        padding: 10px 10px 2px;
        .input-group {
            .input-group-text {
                background-color: transparent;
                border: 0;
                color: $gray-600;
            }
            .form-control {
                border: 0;
                padding-left: 6px;
            }
        }
        .btn {
            white-space: nowrap;
            padding: 9px 15px;
        }
        .shape-06 {
            bottom: -30%;
            right: -17%;
            position: absolute;
            z-index: -1;
            @include respond-below(lg) {
                display: none;
            }
        }
    }
    @include respond-below(lg) {
        padding: 0px 0;
    }

}

.p-dropdown-items {
    padding: 0px !important;
}

.trial-loading {
    display: flex;
    position: relative;
    justify-content: center;
    align-self: center;
    margin: 250px 0px;
}

.service-section-slide {
    position: absolute;
    top: 0;
    max-width: 100% !important;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    z-index: 1000;
}

.product-service {
    .slick-track {
    display: flex !important;
    justify-content: center !important;
    }
        @media (min-width: 1200px) {
            .slick-slide {
                max-width: 400px !important;
            }
        }
        .slick-next {
            right: 10px;

            @media (max-width: 768px) {
                right: -2px;
            }
        }

        .slick-prev {
            left: 10px;
        }
}

.slide-arrows-hero {
    .slick-next {
        right: 15px;

    }

    .slick-prev {
        left: 15px;
    }

    @media (max-width: 768px) {
        .slick-next {
          right: 10px;
        }

        .slick-prev {
          left: 10px;
        }
      }
}

.slide-arrows {
    .slick-next {
        right: -30px;

    }

    .slick-prev {
        left: -30px;
    }

    @media (max-width: 768px) {
        .slick-next {
          right: 5px;
        }

        .slick-prev {
          left: 15px;
        }
      }
}

.trainer-slide {
    background-color: #fff !important;
}

.height-loading-trial {
    height: 600px !important;

    @media (max-width: 767px) {
      height: 400px !important;
    }
  }

.service-content {
    p {
        white-space: break-spaces !important;
        display: -webkit-box;
        -webkit-line-clamp: 2 !important; /* Número de líneas visibles */
        -webkit-box-orient: vertical !important;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.trial-section {
    .btn {
        font-size: 16px !important;
    }
    .bg-primary-transparent {
        border-radius: 50px;
    }
}

.client-section-two {
    background-color: #fff !important;
}

.ripple-effect {
    position: relative;  
    &::before,&::after {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 33px;
        height: 33px;
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        border-radius: 50%;
        box-shadow: 0 0 0 0 $dark-transparent;
        animation: ripple 3s infinite;
    }
  
    &::before {
        -webkit-animation-delay: 0.9s;
        animation-delay: 0.9s;
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
    }
  
    &::after {
        -webkit-animation-delay: 0.6s;
        animation-delay: 0.6s;
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
    }
  }

.download-sec {
    .pricing-plans {
        background-color: transparent !important;
        padding: 0px !important;
    }

    .price-new {
        background-color: transparent !important;
        padding: 0px !important;
        height: auto;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
        margin-right: 15px !important;
    }
}

@keyframes ripple {
    70% {
      -webkit-box-shadow: 0 0 0 20px rgba(244, 68, 56, 0);
      box-shadow: 0 0 0 20px rgba(244, 68, 56, 0);
    }
    100% {
    //   -webkit-box-shadow: 0 0 0 0 rgba(244, 68, 56, 0);
      box-shadow: 0 0 0 0 rgba(244, 68, 56, 0);
    }
}

.round-animate {
    animation: round 5s linear infinite;
}
@keyframes round {
    50% {
      transform: rotate(10deg);
    }
}
.provider-sec {
    padding-top: 20px;
    @include respond-below(lg) {
        padding-top: 10px;
    }
}
.service-item {
    @include rounded(10px);
    overflow: hidden;
    margin-bottom: 40px;
    .service-img {
        position: relative;
        @include rounded(10px 10px 0 0);
        overflow: hidden;
        img {
            @include transition(all 0.3s ease-out 0s);
        }
        .fav-item {
            position: absolute;
            top: 0;
            right: 0;
            padding: 10px;
            z-index: 999;
        }
    }
    .service-content {
        height: 120px;
        padding: 20px;
        border:  1px solid $dark-light-500;
        border-width: 0 1px 1px 1px;
        color: $white !important;
        .service-pro-info {
            p {
                &:hover {
                    color: $primary;
                }
            }
        }
    }
    &:hover {
        .service-img {
            img {
                // @include transition(all 0.3s ease-out 0s);
                // @include transform(scale(1.1));
            }
        }
    }
}
.fav-icon {
    width: 36px;
    height: 36px;
    @include rounded(50%);
    background-color: rgba($gray-900, 0.3);
    color: $white;
    font-size: $font-size-16;  
    flex-shrink: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
}
.img-slider {
    img {
        @include rounded(10px 10px 0 0);
    }
}
.img-slider .owl-dots {
	display: flex;
    align-items: center;
	justify-content: center;
	width: 100%;
	position: absolute;
	bottom: 0;
    .owl-dot span {
        width: unset;
        height: unset;
    }
}
.img-slider .owl-dots .owl-dot {
	width: 5px;
	height: 5px;
	@include rounded(50%);
	background:rgba($white, 0.6);
	margin: 0 0 15px 0;
	margin-right: 4px;
}
.img-slider .owl-dots .owl-dot.active {
	width: 9px;
	height: 9px;
    background: $white;
}
.floating-x {
	-webkit-animation-name: floating-x;
	animation-name: floating-x;
	-webkit-animation-duration: 3s;
	animation-duration: 3s;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
}
@keyframes floating-x {
	0% {
	  -webkit-transform: translateX(30px);
	  transform: translateX(30px);
	}
	50% {
	  -webkit-transform: translateX(10px);
	  transform: translateX(10px);
	}
	100% {
	  -webkit-transform: translateX(30px);
	  transform: translateX(30px);
	}
}
.section-header {
    margin-bottom: 40px;
}
.work-section {
    @include rounded(10px);
    padding: 60px 20px;
    position: relative;
    margin: 60px 0;
    .work-bg1 {
        position: absolute;
        top: 30px;
        left: 0;
    }
    .work-bg2 {
        position: absolute;
        top: 0;
        right: 113px;
    }
    @include respond-below(lg) {
        padding: 50px;
    }
    @include respond-below(md) {
        padding: 30px;
    }
}
.work-item {
    position: relative;
    padding: 0 40px;
    &::before {
        content: "";
        background: url('../../../../public/assets/img/icons/work-icon.svg');
        position: absolute;
        top: 50%;
        right: 0;
        width: 13px;
        height: 10px;
        @include transform(translateY(-50%));
        @include respond-below(lg) {
            content: none;
        }
    }
    &.work-03 {
        &::before {
            content: none;
        }
    }
    @include respond-below(lg) {
        padding: 0;
    }
}
.provider-item {
    padding: 20px;
    border: 1px solid $light-900;
    h6 {
        margin-bottom: 6px;
    }
    p {
        margin-bottom: 6px;
    }
}
.floating-y {
	-webkit-animation-name: floating-y;
	animation-name: floating-y;
	-webkit-animation-duration: 3s;
	animation-duration: 3s;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
}
  @keyframes floating-y {
	0% {
	  -webkit-transform: translateY(-30px) rotate(-15deg);
	  transform: translateY(-30px) rotate(-15deg);
	}
	50% {
	  -webkit-transform: translateY(-10px) rotate(-15deg);
	  transform: translateY(-10px) rotate(-15deg);
	}
	100% {
	  -webkit-transform: translateY(-30px) rotate(-15deg);
	  transform: translateY(-30px) rotate(-15deg);
	}
}
.section {
    padding: 60px 0;
    @include respond-below(lg) {
        padding: 50px 0;
    }
    .view-all {
        margin-top: 40px;
    }
}
.category-item {
    position: relative;
    box-shadow: $box-shadow;
    @include rounded(5px);
    padding: 20px;    
    border: 1px solid $white;   
    @include transition(all 0.5s ease);
    img {
        height: 40px;
    }
    .badge {
        position: absolute;
        top: 0;
        right: 0;
        @include rounded(0 2px 0 5px);
    }
    a {
        display: none;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        @include transition(all 0.5s ease);    
        white-space: nowrap;    
    }
    &:hover {
        @include transition(all 0.5s ease);
        @include rounded(10px);
        border-color: $primary;
        box-shadow: $box-shadow-xl;
        background: url('../../../../public/assets/img/bg/category-bg.svg') no-repeat;
        background-size: cover;
        background-position: top center;
        a {
            display: block;
            bottom: 20px;
            @include transition(all 0.5s ease);  
        }
        .badge {
            @include rounded(0 10px 0 5px);
        }
        p {
            display: none;
            @include transition(all 0.5s ease);  
        }
    }
}
.trend-icon {
    width: 57px;
    height: 57px;
    overflow: hidden;
    position: absolute;
    z-index: 6;
    inset-block-start: -5px;
    inset-inline-start: -6px;
    span {
        position: absolute;
        display: block;
        width: 82px;
        padding: 8px 0;
        color: $white;
        font: 500 16px/1 Lato, sans-serif;
        text-shadow: 0 1px 1px rgba(0,0,0, 0.1);
        text-transform: capitalize;
        text-align: center;
        inset-inline-end: -5px;
        inset-block-start: 1px;
        transform: rotate(-45deg);
        i {
        transform: rotate(45deg);
        }
    }
}
.provide-section {
    position: relative;
    padding: 40px 0;
    z-index: 1;
    .provider-bg1 {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
    }
    .provider-bg2 {
        position: absolute;
        top: 0;
        right: 15%;
        z-index: -1;
    }
}
.rated-section {
    background-color: $light-200;
}
.rated-wrap {
    position: relative;
    @include rounded(10px);
    display: block;
    overflow: hidden;
    .rated-service-img {
        overflow: hidden;
        @include rounded(10px);
    }
    img {
        @include rounded(10px);
        @include transition(all 0.3s ease-out 0s);
        z-index: 1;
    }
    h6 {
        position: absolute;
        bottom: 15px;
        left: 15px;
        z-index: 2;
    }
    &::before {
        content: "";
        width: 100%;
        height: 100%;
        background-color: rgba($black, 0.4);
        position: absolute;
        top: 0;
        left: 0;
        @include rounded(10px);
        @include transition(all 0.3s ease-out 0s);
        z-index: 2;
    }
    &:hover {
        img {
            @include transition(all 0.3s ease-out 0s);
            @include transform(scale(1.1));
        }
    }
}
.fav-icon {
    &:hover, &.selected {
        background: $primary;
        color: $white;
    }
}
.success-icon {
    width: 40px;
    height: 40px;    
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    @include rounded(50%);
    font-size: $font-size-24;
    color: $white;
    background: $success;
    @include respond-below(md) {
        width: 30px;
        height: 30px;   
        font-size: $font-size-24;
    }
}
.business-section {
    position: relative;
    z-index: 1;
    h2, p {
        margin-bottom: 20px
    }      
    &::before {
        content: "";
        width: 423px;
        height: 100%;
        background: url('../../../../public/assets/img/bg/business-bg.png');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        right: 15%;
        z-index: -1;
        @include respond-below(lg) {
            content: none;
        }
    }
}
.business-img {
    margin-left: 54px;
    img {
        @include rounded(10px);
    }
    @include respond-below(lg) {
        margin-left: 0;
    }
}
.service-section {
    position: relative;
    z-index: 1;
    background-color: $dark-secondary;
}
.blog-item {
    box-shadow: $box-shadow-md;
    @include rounded(10px);
    margin-bottom: 40px;
    .blog-img {
        overflow: hidden;
        @include rounded(10px);
        img {            
            @include transition(all 0.3s ease-out 0s);
            @include rounded(10px);
        }
    }
    .blog-content {
        padding: 20px;
        .badge {
            font-size: $font-size-10;
            font-weight: $font-weight-medium;
            padding: 8px;
            @include rounded(20px);
        }
    }
    &:hover {
        img {            
            @include transition(all 0.3s ease-out 0s);
            @include transform(scale(1.1));
        }
    }
}
.two-line-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.main-links {
    margin-top: 16px;
    a {
        text-decoration: underline;
        margin-bottom: 16px;
        color: $gray-600;
        display: block;
        &:hover {
            color: $primary;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.info-section {
    .accordion-item {
        margin-bottom: 44px;
        &:last-child {
            margin-bottom: 0;
        }
        @include respond-below(lg) {
            margin-bottom: 34px;
        }
    }
}
.testimonial-section {
    position: relative;
    z-index: 1;
}
.testimonial-bg1 {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
}
.testimonial-bg2 {
    position: absolute;
    left: 0;
    top: 35%;
    z-index: -1;
}
.testimonial-item {
    border: 1px solid $border-color;
    padding: 20px;
    @include rounded(10px);
    box-shadow: $box-shadow-md;
    margin-bottom: 40px;
}
.nav-center {
    .owl-nav button.owl-next,
    .owl-nav button.owl-prev {
        width: 32px;
        height: 32px;
        font-size: $font-size-16;
        color: $gray-900;
        background: $white;
        box-shadow: 0px 4px 14px 0px rgba(141, 141, 141, 0.25);
        text-shadow: none;
        top: 0;
        cursor: pointer;
        border: 0;
        margin-top: 0;
        margin-bottom: 0;
        border-radius: 50px;
        position: absolute;
        top: 50%;
        @include transform(translateY(-50%));        
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        justify-content: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        &:hover {
            background: $primary;
            color: $white;
        }
        @include respond-below(md) {
            width: 36px;
            height: 36px;
        }
    }
    .owl-nav button.owl-next {
        right: -20px;
        @include respond-below(lg) {
            right: -5px;
        }
    }
    .owl-nav button.owl-prev {
        left: -20px;
        @include respond-below(lg) {
            left: -5px;
        }
    }
}
.progress-fieldset {
    .progress {
        height: 4px;
    }
}
.group-radio {
    .form-check {
        .form-check-input {
            font-size: $font-size-16;
        }
    }
}
.category-badge {
    .badge {
        background: $light-300;
        font-size: $font-size-14;
        font-weight: $font-weight-normal;
        padding: 8px;
        @include rounded(5px);
        color: $gray-600;
    }
}
#providerwizard {
    li {
        span {
            background-color: $light;
            color: $gray-900;
            font-size: $font-size-16;
            font-weight: $font-weight-medium;
        }
        &.active {
            span {
                background-color: $dark;
                color: $white;
            }
        }
        &.activated {
            span {
                background-color: $success;
                color: $white;
            }
        }
    }
}
fieldset {
    &.field-one {
        display: block;
    }
    display: none;
} 
fieldset {
    &#first-field {
        display: block;
    }
    display: none;
}
.back-to-top-icon.show {
    opacity: 1;
    @include transition(all 0.5s ease);
}
.back-to-top-icon {
    position: fixed;
    right: 15px;
    bottom: 30px;
    z-index: 9999;
    width: 36px;
    height: 36px;
    text-align: center;
	border-radius: 50%;
	background: $dark;
    color: $white;
    cursor: pointer;
    border: 0;
    text-decoration: none;
    transition: opacity 0.2s ease-out;
    opacity: 0;
    @include transition(all 0.5s ease);
    &:hover {
        color: $white;
        background: $primary;
    }
}
.animation-float { 
    animation: float 3000ms linear 500ms infinite alternate both; 
} 
@keyframes float {
    0% { transform: translateY(0px); }
    50% { transform: translateY(10px); }
    100% { transform: translateY(20px); }
}
.img-slider {
    &.nav-center {
        .owl-nav {
            button {
                display: none;
                @include transition(all 0.5s ease);
                width: 24px;
                height: 24px;
                font-size: $font-size-12;
                &.owl-prev {
                    left: 16px;
                    &:hover {
                        background: $primary;
                        color: $gray-900 !important;
                    }
                }
                &.owl-next {
                    right: 16px;
                    &:hover {
                        background: $primary;
                        color: $gray-900 !important;
                    }
                }
                &:hover {
                    color: $dark;
                }
            }
        }
        &:hover {
            .owl-nav {
                button {
                    @include transition(all 0.5s ease);
                    display: block;
                }
            }
        }
    }
}
.gallery-slider {
    &.nav-center {
        .owl-nav {
            button {
                width: 24px;
                height: 24px;
                font-size: $font-size-12;
                &.owl-prev {
                    left: 16px;
                }
                &.owl-next {
                    right: 16px;
                }
            }
        }
    }
}

.authentication_field{
    fieldset {
        &#login-page {
            display: block;
        }
        display: none;
    }
}

.service-item {
    .service-content {
        h6 {
            a{
                display: inline;
                background-image: linear-gradient($primary, $primary), linear-gradient($primary, $primary);
                background-size: 0% 2px, 0 2px;
                background-position: 100% 100%, 0 100%;
                background-repeat: no-repeat;
                transition: background-size 0.4s linear;
                &:hover{
                    background-size: 0 2px, 100% 2px;
                }
            }
        }
    }
}
.apexcharts-toolbar {
    display: none !important;
}